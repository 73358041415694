.ew_resizer {
  position: absolute;
  top: 2px;
  bottom: 2px;
  right: -3px;
  width: 7px;
  cursor: ew-resize;
  z-index: 15;
}

.ns_resizer {
  position: absolute;
  bottom: -3px;
  left: 2px;
  right: 2px;
  height: 7px;
  cursor: ns-resize;
  z-index: 15;
}